












import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import AppHeader from '~/components/AppHeader.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

export default defineComponent({
  name: 'LoginLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    IconSprite,
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen, isWishlistSidebarOpen, isLoginModalOpen, toggleLoginModal,
    } = useUiState();

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
    };
  },
  head: {
    // link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});
