

















import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import AppCheckoutHeader from '~/components/AppCheckoutHeader.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    IconSprite,
    AppCheckoutHeader,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    Notification: () =>
      import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    ReviewsPayMethod: () =>
      import(
        /* webpackPrefetch: true */ '~/components/Footer/ReviewsPayMethod/ReviewsPayMethod.vue'
      ),
    Subscription: () =>
      import(
        /* webpackPrefetch: true */ '~/components/Footer/Subscription/Subscription.vue'
      ),
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();

    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
    };
  },
  head: {
    // link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
  },
});
