import { Logger } from '~/helpers/logger';
import { Cart, RemoveItemFromCartInput } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import { CustomQuery, CustomHeaders } from '~/types/core';

export const removeItemCommand = {
  execute: async (
    context: VsfContext,
    {
      currentCart,
      product,
      customQuery = { removeItemFromCart: 'cart-remove-item' },
      customHeaders,
    },
  ) => {
    Logger.debug('[Magento]: Remove item from cart', {
      product,
      currentCart,
    });

    const item = currentCart.items.find((cartItem) => cartItem.uid === product.uid);

    if (!item) {
      return;
    }

    const removeItemParams: RemoveItemFromCartInput = {
      cart_id: currentCart.id,
      cart_item_uid: item.uid,
    };
    try {
      const { data } = await context.$magento.api.removeItemFromCart(
        removeItemParams,
        customQuery as CustomQuery,
        customHeaders as CustomHeaders,
      );
      Logger.debug('[removeItemFromCart Result]:', { data });
      // eslint-disable-next-line consistent-return
      return data
        .removeItemFromCart
        .cart as unknown as Cart;
    } catch (error) {
      Logger.debug('[removeItemFromCart Error]:', error);
      throw new Error(error.message);
    }
  },
};
