import type { RawLocation } from 'vue-router';

import { useRouter, useContext } from '@nuxtjs/composition-api';
import { useUser } from '~/modules/customer/composables/useUser';
import { useCart } from '~/modules/checkout/composables/useCart';

type LinkGroupItem = { id: string, label: string, icon?: string, svg?: string, name?: string, link?: RawLocation };

export const useSidebarLinkGroups = () => {
  const { localeRoute } = useContext();
  const { logout } = useUser();
  const { clear } = useCart();
  const router = useRouter();

  const sidebarLinkGroups: LinkGroupItem[] = [
    {
      id: 'my-overview',
      label: 'overview',
      name: 'account-my-overview',
      svg: 'account_overview',
      link: { name: 'account-my-overview' },
    },
    {
      id: 'order-history',
      label: 'Order',
      name: 'account-order-history',
      svg: 'user-account-order',
      link: { name: 'account-order-history' },
    },
    {
      id: 'my-profile',
      label: 'profile',
      name: 'account-my-profile',
      svg: 'user-account-profile',
      link: { name: 'account-my-profile' },
    },
    {
      id: 'address-book',
      label: 'Addresses book',
      name: 'account-addresses-details',
      svg: 'user-account-address',
      link: { name: 'account-addresses-details' },
    },
    // {
    //   id: 'my-payment-methods',
    //   label: 'payment method',
    //   name: 'account-my-newsletter',
    //   icon: 'safety',
    //   link: { name: 'account-my-newsletter' },
    // },
    {
      id: 'my-wishlist',
      label: 'wishlist',
      name: 'account-my-wishlist',
      svg: 'heart',
      link: '/wish',
    },
    {
      id: 'my-store-credit',
      label: 'store credit',
      name: 'account-my-store-credit',
      svg: 'credits',
      link: { name: 'account-my-store-credit' },
    },
    // {
    //   id: 'my-reviews',
    //   label: 'My reviews',
    //   name: 'account-my-reviews',
    //   icon: 'drag',
    //   link: { name: 'account-my-reviews' },
    // },
    // {
    //   id: 'log-out',
    //   icon: 'logout',
    //   label: 'Log out',
    // },
  ];

  const logoutUser = async () => {
    await logout({});
    await clear({});
    await router.push(localeRoute({ name: 'home' }));
  };

  return { sidebarLinkGroups, logoutUser };
};
