import { ComposableFunctionArgs } from '~/composables/types';
import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const loadCartCommand = {
  execute: async (context: VsfContext, params: ComposableFunctionArgs<{
    realCart?: boolean;
  }>) => {
    const apiState = context.$magento.config.state;
    Logger.debug('[Magento Storefront]: loadCartCommand Loading Cart');
    const customerToken = apiState.getCustomerToken();
    const virtual = !params.realCart;
    const locale = apiState.getLocale();
    const createVirtualCart = () => (null as Cart);

    const createRealCart = async (): Promise<string> => {
      Logger.debug('[Magento Storefront]: useCart.load.createNewCart');

      apiState.setCartId();

      const { data } = await context.$magento.api.createEmptyCart();
      Logger.debug('[createNewCart Result]:', { data });

      apiState.setCartId(data.createEmptyCart);

      return data.createEmptyCart;
    };

    const getCartData = async (id: string) => {
      Logger.debug('[Magento Storefront]: useCart.load.getCartData ID->', id);

      const cartQueryString = locale === 'cn' ? 'cart-query-toal-cn' : 'cart-query-toal';
      const { data, errors } = await context.$magento.api.cart(id, {
        cart: cartQueryString,
      });
      Logger.debug('[***Api.cart Result]:', { data });

      if (!data?.cart && errors?.length) {
        console.log('[GetCartData Error:]', errors[0]);
        throw errors[0];
      }

      data.cart.items = data.cart.items.filter(Boolean);
      return data.cart as unknown as Cart;
    };

    const getCart = async (virtualCart: boolean, cartId?: string) => {
      if (!cartId) {
        if (virtualCart) {
          return createVirtualCart();
        }

        // eslint-disable-next-line no-param-reassign
        cartId = await createRealCart();
        apiState.setCartId(cartId);
      }

      return getCartData(cartId);
    };

    // Try to load cart for existing customer, clean customer token if not possible
    if (customerToken) {
      try {
        // 先获取ID，有则获取详情；没有则先取id再获取详情;
        const currentcartId = apiState.getCartId();
        if (!currentcartId) {
          const { data, errors } = await context.$magento.api.customerCart();
          Logger.debug('[*** Get api.customerCart Result]:', { data, errors });

          if (!data?.customerCart && errors?.length) {
            throw errors[0];
          }
          apiState.setCartId(data.customerCart.id);
          Logger.debug('[*** SetCartID Success:]:', data.customerCart.id);
          // 根据cartID获取详情
        }
        return await getCart(virtual, apiState.getCartId()) as unknown as Cart;
        // --------
        // const { data, errors } = await context.$magento.api.customerCart();
        // Logger.debug('[*** Get api.customerCart Result]:', { data, errors });

        // if (!data?.customerCart && errors?.length) {
        //   throw errors[0];
        // }

        // apiState.setCartId(data.customerCart.id);
        // data.customerCart.items = data.customerCart.items.filter(Boolean);

        // return data.customerCart as unknown as Cart;
      } catch (e) {
        console.log('[*** Get Cart Error:==]', e);
        apiState.setCustomerToken();
      }
    }

    try {
      // If it's not existing customer check if cart id is set and try to load it
      const cartId = apiState.getCartId();
      return await getCart(virtual, cartId);
    } catch {
      apiState.setCartId();
      return await getCart(virtual);
    }
  },
};
