import { defineStore } from 'pinia';
import type { Customer } from '~/modules/GraphQL/types';

interface CustomerState {
  user: Customer | null,
  isLoggedIn: boolean,
  storeCredit: string | null
}

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false,
    storeCredit: null,
  }),
  actions: {
    setIsLoggedIn(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn;
    },
    setUser(payload) {
      this.user = payload;
    },
    setStoreCredit(credit: null | string) {
      if (credit) {
        this.storeCredit = credit;
      }
    },
  },
});
