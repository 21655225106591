


























































































































































































import { SfOverlay, SfHeader, SfButton, SfBadge } from '@storefront-ui/vue';

import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  useRoute,
} from '@nuxtjs/composition-api';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import { useUiHelpers, useUiState } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import { useWishlistStore } from '~/modules/wishlist/store/wishlistStore';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import HeaderLogo from '~/components/HeaderLogo.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { useSidebarLinkGroups } from '~/modules/account/pages/MyAccount/useSidebarLinkGroups';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import useUiNotification from '~/composables/useUiNotification';
import { useTopBar } from './TopBar/useTopBar';

export default defineComponent({
  components: {
    HeaderNavigation,
    SfHeader,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    SfOverlay,
    MobileCategorySidebar: () =>
      import(
        '~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue'
      ),
    CurrencySelector: () => import('~/components/CurrencySelector.vue'),
    // StoreSwitcher: () => import('~/components/StoreSwitcher.vue'),
    // SearchBar: () => import('~/components/Header/SearchBar/SearchBar.vue'),
    SearchResults: () =>
      import('~/components/Header/SearchBar/SearchResults.vue'),
    /* webpackPrefetch: true */
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const {
      app,
      localePath,
      app: { i18n },
    } = useContext();
    const {
      toggleCartSidebar,
      toggleWishlistSidebar,
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    const categories = useCategoryStore();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const isShowUserHover = ref<boolean>(false);
    const timeoutID = ref(null);
    const {
      cart,
      loadTotalQty,
      error: cartError,
      clear: clearCart,
    } = useCart();
    const { loadItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();
    const { send: sendNotification } = useUiNotification();

    const { hasCurrencyToSelect, hasStoresToSelect } = useTopBar();

    const isSearchOpen = ref(false);
    const { logoutUser } = useSidebarLinkGroups();
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const wishlistStore = useWishlistStore();
    const wishlistItemsQty = computed(
      () => wishlistStore.wishlist?.items_count ?? 0
    );
    const wishlistHasProducts = computed(() => wishlistItemsQty.value > 0);
    const goToWish = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'wish' }));
      } else {
        sendNotification({
          id: Symbol('login_view_wish'),
          message: i18n.t('Must be logged in to view your wish list') as string,
          persist: false,
          type: 'info',
          icon: 'info',
        });
        await router.push(localePath('/user/signin'));
      }
    };

    const handleHoverIn = () => {
      isShowUserHover.value = true;
      if (timeoutID.value) {
        clearTimeout(timeoutID.value);
      }
    };
    const handleHoverLeave = () => {
      timeoutID.value = setTimeout(() => {
        isShowUserHover.value = false;
      }, 1000);
    };
    const categoryTree = ref<CategoryTree[]>([]);
    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'account-my-overview' }));
      } else {
        await router.push(localePath('/user/signin'));
      }
    };

    const handleRoute = async (pathStr) => {
      await router.push(localePath(pathStr));
    };

    const goToCart = async () => {
      await router.push(app.localeRoute({ name: 'cart' }));
    };

    const fetch = async () => {
      if (
        !categories.globalCateTree ||
        !categoryList ||
        categoryList?.value?.length === 0
      ) {
        await categoriesListLoad({ pageSize: 20 });
      }
      await loadTotalQty(); // cart qty
      categoryTree.value = categories.globalCateTree[0]?.children.filter(
        (category) => category.include_in_menu
      );
      if (categoryTree?.value?.length) {
        // add Brand
        categoryTree.value.push({
          children: [],
          level: 2,
          name: app.i18n.t('Brands'),
          url_path: 'brand',
          url_suffix: '',
        } as CategoryTree);
      }
    };

    const loadCategoryMenu = async () => {
      if (categories.categories === null) {
        await categories.load();
      }
    };
    onMounted(async () => {
      if (process.client) {
        if (isAuthenticated.value && (!user.value || !user.value.firstname)) {
          await loadUser();
        }
        if (isAuthenticated.value) {
          await loadItemsCount();
        }
        if (app.$device.isDesktop) {
          await fetch();
        } else {
          await loadCategoryMenu();
        }
        const { path } = route.value;
        if (path.indexOf('thank-you') < 0) {
        // thank you page no need to fetch
          await loadTotalQty(); // cart qty
        }
        if (cartError.value.loadTotalQty) {
          await clearCart({ customQuery: { cart: 'cart' }, customHeaders: {} });
        }
      }
    });

    const toggleSearchSidebar = (newVal) => {
      if (newVal === false && isSearchOpen.value === false) return;
      isSearchOpen.value = !isSearchOpen.value;
    };
    // console.log('categoryTree', categoryTree);

    return {
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      toggleWishlistSidebar,
      wishlistHasProducts,
      wishlistItemsQty,
      hasCurrencyToSelect,
      hasStoresToSelect,
      goToWish,
      goToCart,
      user,
      toggleSearchSidebar,
      isShowUserHover,
      handleHoverIn,
      handleHoverLeave,
      handleRoute,
      logoutUser,
      loadCategoryMenu,
      isMobileMenuOpen,
      toggleMobileMenu,
    };
  },
  // head() {},
});
