

























import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfButton, SfImage } from '@storefront-ui/vue';
import type { NuxtError } from '@nuxt/types';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  components: {
    SfButton,
    SfImage,
  },
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup(props) {
    // console.log('error page', props.error);
    const handleToHome = () => {
      window.location.href = '/';
    };
    return {
      handleToHome,
      addBasePath,
    };
  },
});
