import locale77427e78 from '../../lang/en.js'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"gb","numberFormats":{"gb":{"currency":{"style":"currency","currency":"GBP","currencyDisplay":"symbol"}}}},
  vueI18nLoader: false,
  locales: [{"code":"gb","file":"en.js","iso":"en-gb","storeCode":"default","defaultCurrency":"GBP","domain":"www.unineed.com"},{"code":"cn","file":"cn.js","iso":"zh-cn","storeCode":"default","defaultCurrency":"GBP","domain":"cn.unineed.com"},{"code":"us","file":"en.js","iso":"en-us","storeCode":"uus_2","defaultCurrency":"USD","domain":"us.unineed.com"},{"code":"mex","file":"mex.js","iso":"mex","storeCode":"mx_website_store_view","defaultCurrency":"MXN","domain":"mx.unineed.com"}],
  defaultLocale: "mex",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/var/www/lang",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: true,
  seo: false,
  baseUrl: "http://127.0.0.1:3001",
  vuex: {"moduleName":"i18n","syncLocale":false,"syncMessages":false,"syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  country: "UK",
  autoRedirectByLocale: false,
  switchLocalePath: "/:lang/:route",
  setLocaleCookie: true,
  getLocaleCookie: "i18n_loc",
  reloadOnLanguageChange: true,
  normalizedLocales: [{"code":"gb","file":"en.js","iso":"en-gb","storeCode":"default","defaultCurrency":"GBP","domain":"www.unineed.com"},{"code":"cn","file":"cn.js","iso":"zh-cn","storeCode":"default","defaultCurrency":"GBP","domain":"cn.unineed.com"},{"code":"us","file":"en.js","iso":"en-us","storeCode":"uus_2","defaultCurrency":"USD","domain":"us.unineed.com"},{"code":"mex","file":"mex.js","iso":"mex","storeCode":"mx_website_store_view","defaultCurrency":"MXN","domain":"mx.unineed.com"}],
  localeCodes: ["gb","cn","us","mex"],
}

export const localeMessages = {
  'en.js': () => Promise.resolve(locale77427e78),
  'cn.js': () => import('../../lang/cn.js' /* webpackChunkName: "lang-cn.js" */),
  'mex.js': () => import('../../lang/mex.js' /* webpackChunkName: "lang-mex.js" */),
}
