import { Middleware } from '@nuxt/types';
import { usePageStore } from '~/stores/page';
import { Logger } from '~/helpers/logger';
import { RoutableInterface } from '~/modules/GraphQL/types';

const urlResolverMiddleware: Middleware = async (context) => {
  const pageStore = usePageStore();
  const { path } = context.route;
  console.log('[== url-resolver Path=]', path);
  if (path.indexOf('webpack_') >= 0) return;

  let clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');
  Logger.info('middleware/url-resolver', clearUrl);

  // /product/xxxxxx.html
  if (/^\/product\/.*/.test(clearUrl)) {
    clearUrl = clearUrl.replace('/product', '');
  }
  if (/^\/category\/.*/.test(clearUrl)) {
    clearUrl = clearUrl.replace('/category', '');
  }

  //  ** search module
  if (clearUrl.indexOf('/search') > -1) {
    pageStore.$patch((state) => {
      state.routeData = {
        type: 'SEARCH',
        uid: 'SEC=',
      };
    });
    return;
  }

  const { data, errors } = await context.app.$vsf.$magento.api.route(clearUrl);

  Logger.info('middleware/url-resolver/result', { data, errors });

  const results: RoutableInterface | null = data?.route ?? null;

  if (!results || errors?.length) context.error({ statusCode: 404 });
  // pageStore.$patch((state) => {
  //   state.routeData = results;
  // });
  pageStore.routeData = results;
};

export default urlResolverMiddleware;
