






























import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute,
  defineComponent,
  onMounted,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    LoadWhenVisible,
    LazyHydrate,
    IconSprite,
    TopShippingCarousel: () =>
      import(
        /* webpackPrefetch: true */ '~/components/TopShippingCarousel.vue'
      ),
    CNTopShippingCarousel: () =>
      import(
        /* webpackPrefetch: true */ '~/components/CNTopShippingCarousel.vue'
      ),
    AppHeader: () =>
      import(/* webpackPrefetch: true */ '~/components/AppHeader.vue'),
    TopBar: () =>
      process.client ? import('~/components/TopBar/TopBar.vue') : null,
    CookieConsent: () =>
      process.client ? import('~/components/CookieConsent.vue') : null,
    AppFooter: () =>
      import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () =>
      import(
        /* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'
      ),
    Notification: () =>
      import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
    ReviewsPayMethod: () =>
      import(
        /* webpackPrefetch: true */ '~/components/Footer/ReviewsPayMethod/ReviewsPayMethod.vue'
      ),
    Subscription: () =>
      import(
        /* webpackPrefetch: true */ '~/components/Footer/Subscription/Subscription.vue'
      ),
  },

  setup() {
    const route = useRoute();
    const currentLocale = ref('');
    const currentWebSite = ref('');
    const {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();

    onMounted(() => {
      const { locale } = useI18n();
      currentLocale.value = locale;
      console.log('**** currentLocale defalut', currentLocale.value);
      const {
        $config: { currentSite },
      } = useContext();
      currentWebSite.value = currentSite;
      console.log('currentSite:', currentSite);
    });
    return {
      isCartSidebarOpen,
      isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      currentLocale,
      currentWebSite,
    };
  },
  head() {
    // link: [{ rel: 'stylesheet', href: '/_nuxt/fonts.css' }],
    const currentLocale = this.$i18n.locale;
    // console.log('Layout current Locale:', currentLocale);
    const gbScript = [
      {
        id: 'ze-snippet',
        hid: 'ze-snippet',
        src: 'https://static.zdassets.com/ekr/snippet.js?key=92797b58-e4d5-42b4-8ea2-e1b13132b1fc',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        hid: 'awin-track',
        id: 'awin-track',
        src: 'https://www.dwin1.com/5350.js',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        hid: 'uk-kelkoo-tracking',
        id: 'uk-kelkoo-tracking',
        src: 'https://s.kk-resources.com/leadtag.js',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        id: 'UK-Baidu-Statistics',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement('script');
            hm.src = "https://hm.baidu.com/hm.js?a26516ae6e50505a6a08aa3b19d30b14";
            var s = document.getElementsByTagName('script')[0]; 
            s.parentNode.insertBefore(hm, s);
          })(); `,
      },
    ];
    const usScript = [
      {
        id: 'ze-snippet',
        hid: 'ze-snippet',
        src: 'https://static.zdassets.com/ekr/snippet.js?key=92797b58-e4d5-42b4-8ea2-e1b13132b1fc',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
      },
      {
        id: 'US-Baidu-Statistics',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          var hmt = hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?4642875e00813ca0cb35226ab637238b";
            var s = document.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(hm, s);
          })();`,
      },
      {
        id: 'US-Pinterest-tag',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          <!-- Pinterest Tag -->
            !function(e){if(!window.pintrk){window.pintrk = function () {
              window.pintrk.queue.push(Array.prototype.slice.call(arguments))};var
                n=window.pintrk;n.queue=[],n.version="3.0";var
                t=document.createElement("script");t.async=!0,t.src=e;var
                r=document.getElementsByTagName("script")[0];
                r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
              pintrk('load', '2613925467137', {em: '<user_email_address>'});
              pintrk('page');
            var img = document.createElement("img");
            img.src = "https://ct.pinterest.com/v3/?event=init&tid=2613925467137&pd[em]=<hashed_email_address>&noscript=1";
            img.setAttribute("height", "1");
            img.setAttribute("width", "1");
            img.setAttribute("alt", "");
            img.style.display = "none";
            document.addEventListener("DOMContentLoaded", function() {
              document.body.appendChild(img);
             });
            <!-- end Pinterest Tag -->`,
      },
    ];
    const cnScript = [
      {
        id: 'Rakten-Marketing-Tracking',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          (function (url) {
              /*Tracking Bootstrap
              Set Up DataLayer objects/properties here*/
              if(!window.DataLayer){
                  window.DataLayer = {};
              }
              if(!DataLayer.events){
                  DataLayer.events = {};
              }
              DataLayer.events.SiteSection = "1";
                          
              var loc, ct = document.createElement("script"); 
              ct.type = "text/javascript"; 
              ct.async = true;
              ct.src = url;
              loc = document.getElementsByTagName('script')[0];
              loc.parentNode.insertBefore(ct, loc);
          }(document.location.protocol + "//intljs.rmtag.com/114906.ct.js"));
        `,
      },
      {
        id: 'CN-Baidu-Statistics',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
          var _hmt = _hmt || [];
          (function() {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?57dfcfdbea5be460f02efc02cc5ef9d8";
            var s = document.getElementsByTagName("script")[0]; 
            s.parentNode.insertBefore(hm, s);
          })();`,
      },
    ];
    const scripts = {
      gb: gbScript,
      us: usScript,
      cn: cnScript,
    };
    const script = scripts[currentLocale] || [];
    if (process.client) {
      script.push({
        hid: 'Google-Tag-Manager',
        id: 'Google-Tag-Manager',
        defer: true,
        type: 'text/javascript',
        charset: 'utf-8',
        innerHTML: `
        (function(w,d,s,l,i){
          w[l]=w[l]||[];
          w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-PSXRM6K9');
        document.addEventListener("DOMContentLoaded", function() {
            var iframe = document.createElement("iframe");
            iframe.src="https://www.googletagmanager.com/ns.html?id=GTM-PSXRM6K9";
            iframe.width = "0";
            iframe.height = "0";
            iframe.display = "none";
            iframe.style.visibility = "hidden";
            document.body.appendChild(iframe);
      });
        `,
      });
    }

    const enMeta = [
      {
        name: 'google-site-verification',
        content: '45B8X3QXx__9LzOm4szVMBO-b97h_WFLziKQ0UpAXmA',
      },
    ];
    const mxMeta = [
      {
        name: 'google-site-verification',
        content: 'eD_FuY1n2o61SowWUCKUO69MZz1flEFCf-_cssmj8oY',
      },
    ];
    const metas = {
      gb: enMeta,
      us: enMeta,
      cn: [],
      mex: mxMeta,
    };
    debugger;
    const meta = metas[currentLocale] || [];
    return {
      script,
      meta,
    };
  },
});
